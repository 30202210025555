import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { getClientInstance } from "@/services/client/clientProvider";
import {
  CreateSubCompanyRequestModel,
  SubCompanyModel,
  UpdateSubCompanyRequestModel,
} from "@/services/types/subCompanies";
import { StatusType } from "@/services/types/base";

export function getDefaultFilter() {
  return {
    search: "",
    status: null,
  };
}

export const useSubCompaniesStore = defineStore("subcompanies", () => {
  const isDataLoading = ref(false);
  const companies = ref<SubCompanyModel[]>([]);
  const filter = ref<{ search: string; status: string }>(getDefaultFilter());

  const getters = {
    getFilteredCompanies: computed<SubCompanyModel[]>(() => {
      const status = (
        filter.value.status ? filter.value.status : null
      ) as StatusType;
      const search = filter.value.search ? filter.value.search : "";
      return filterByStatus(status, filterByName(search, companies.value));
    }),
  };

  const actions = {
    async fetchSubCompanies() {
      isDataLoading.value = true;
      try {
        const client = getClientInstance();
        const resp = await client.getEmployeesList();
        companies.value = resp.subcompanies;
      } finally {
        isDataLoading.value = false;
      }
    },

    async createSubCompany(payload: CreateSubCompanyRequestModel) {
      await getClientInstance().createSubCompany(payload);
    },

    async deleteSubCompanies(ids: number[]) {
      await getClientInstance().removeSubCompanies({ ids });
    },

    async updateSubCompany(payload: UpdateSubCompanyRequestModel) {
      await getClientInstance().updateSubCompany(payload);
    },
  };

  return {
    isDataLoading,
    companies,
    filter,
    ...getters,
    ...actions,
  };
});

function filterByStatus(
  status: StatusType,
  list: SubCompanyModel[]
): SubCompanyModel[] {
  if (status === null) {
    return list;
  } else {
    return list.filter((item) => item.status === status);
  }
}

function filterByName(
  search: string,
  list: SubCompanyModel[]
): SubCompanyModel[] {
  if (search.length >= 2) {
    return list.filter(
      (i) =>
        i.name.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0
    );
  } else {
    return list;
  }
}
