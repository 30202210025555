var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"SubCompaniesListTable"},[_c('b-table',{staticClass:"companies-table",attrs:{"items":_setup.getFilteredCompanies,"fields":_setup.fields,"striped":"","hover":"","sort-icon-left":"","busy":_setup.isDataLoading},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: `SubCompanyUsers`,
          params: { companyId: data.item.id },
        }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_setup.getStatus(data.item.status))+" ")]}},{key:"cell(users)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.users.length)+" ")]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"icon-wrapper"},[_c('b-dropdown',{attrs:{"size":"md","variant":"link","offset":"-120","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":_setup.actionsIcon}}),_c('span',{staticClass:"sr-only"},[_vm._v("Actions")])]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
              name: `SubCompanyUsers`,
              params: { companyId: row.item.id },
            }}},[_vm._v(" Пользователи ")]),_c('b-dropdown-item',{on:{"click":function($event){return _setup.handleEditCompanyClick(row.item)}}},[_vm._v(" "+_vm._s(_vm.$t("actions.edit"))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _setup.handleDeleteCompanyClick(row.item)}}},[_vm._v(" "+_vm._s(_vm.$t("actions.delete"))+" ")])],1)],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center p-3"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]},proxy:true}])}),_c(_setup.EditSubcompanyFormModal,{attrs:{"company":_setup.selectedCompany},model:{value:(_setup.editCompanyModalShow),callback:function ($$v) {_setup.editCompanyModalShow=$$v},expression:"editCompanyModalShow"}}),_c(_setup.ConfirmationComponent,{attrs:{"show":_setup.deleteCompanyModalShow,"title":"Удаление компании","onOk":_setup.deleteComapny,"onCancel":_setup.cancleDeleteCompany}},[_vm._v(" Вы уверены, что хотите удалить компанию "+_vm._s(_setup.selectedCompany ? _setup.selectedCompany.name : ``)+"? ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }